import type { Coordinate } from '@API/manager/polygon';

/**
 * km^2 단위의 면적. 소숫점6자리까지 구함(아주 작은 면적을 대비하기 위함 (더 작아지면 zoom level계산 어려워짐))
 * @param {Coord[]} polygon - polygon coordinates array
 * @param {number} polygon[].lat - latitude of coordinates
 * @param {number} polygon[].lng - longitude of coordinates
 * @returns {number}
 *
 * [참조 알고리즘](https://stackoverflow.com/questions/16285134/calculating-polygon-area)
 * - NOTE: naver맵 유틸을 이용해서 구하는 면적(`$naverMap.getAreaSize()`)과 미세한 차이가 있음
 * */
function calcPolygonArea(polygon: Coordinate[]): number {
  let total = 0;
  for (let i = 0; i < polygon.length; i++) {
    const addX = polygon[i][0];
    const addY = polygon[i === polygon.length - 1 ? 0 : i + 1][1];
    const subX = polygon[i === polygon.length - 1 ? 0 : i + 1][0];
    const subY = polygon[i][1];

    total += addX * addY * 0.5 - subX * subY * 0.5;
  }
  return +(Math.abs(total) * 10 ** 4).toFixed(6);
}

export default calcPolygonArea;
